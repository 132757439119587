import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Component
import FloatSelo from '../components/FloatingSelo/selo'

// Imagens
import handFlower from '../images/sustentabilidade/icons/Sustentabilidade_1.svg'
import icon1 from '../images/oleo-basico/icons/Oleo_Basico_1.svg'
import icon2 from '../images/oleo-basico/icons/Oleo_Basico_2.svg'
import icon3 from '../images/oleo-basico/icons/Oleo_Basico_3.svg'
import icon4 from '../images/oleo-basico/icons/Oleo_Basico_4.svg'
import icon5 from '../images/oleo-basico/icons/benzeno.svg'
import icon6 from '../images/oleo-basico/icons/Oleo_Basico_6.svg'

import doc from '../content/LWART_BASE_OILS 2_ final 1.pdf'

const MoldeDesempenho = props => (
  <>
    <div className="cardIcon col-lg-auto col-auto mt-4 mb-lg-4 mb-2">
      <div className="text-center">
        <img src={props.icon} alt="" />
      </div>
    </div>
    <div className="col-lg-6 col-auto mt-lg-4 d-flex align-items-center justify-content-lg-start justify-content-center p-0 m-0">
      <p className="text-white text-lg-left text-center text-uppercase font-Spinnaker pl-lg-2">{props.text}</p>
    </div>
  </>
)

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)
  const intl = useIntl()

  return (
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage"
      >
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-12 text-center">
              <h1 className="text-white" style={{ fontSize: '86px' }}>G2</h1>
              <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.g2.banner_lwart.text' }))}</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

const ProdutoSuperior = ({ arquivo, img }) => {
  const intl = useIntl()

  return <>
    <div className="gradient-blue-to-green"></div>
    <div className="container pr-lg-5 pl-lg-5 pt-lg-0 pb-lg-0 pb-5 pt-5 px-0">
      <div className="row mr-0 ml-0 pr-0 pl-0 h-100 justify-content-end align-items-center px-4 px-lg-0">
        <div className="col-lg-5 px-0 px-lg-3">
          <h2 className="text-lwart-blue pb-2">{parse(intl.formatMessage({ id: 'paginas.g2.produto_superior.title' }))}</h2>
          <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.g2.produto_superior.p1' }))}
          </p>
          <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.g2.produto_superior.p2' }))}
          </p>
          <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.g2.produto_superior.p3' }))}
          </p>
          <a href={ intl.locale === 'pt' ? arquivo : doc } target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade mt-2">{parse(intl.formatMessage({ id: 'paginas.g2.produto_superior.link' }))}</button></a>
        </div>
        <div className="col-lg-7 d-lg-block d-none">
          <GatsbyImage image={img} alt="Imagem de fundo G2" className="h-100" />
        </div>
      </div>
    </div>
  </>
}

const AltoDesempenho = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.section className="bg-lwart-darkblue full m-0 overflow-auto" ref={ref} animate={controls}>
      <div className="container pt-5 pr-lg-5 pl-lg-5 pb-5 pb-0">
        <div className="row m-0 p-0 h-100 align-items-center">
          <div className="col-lg-4 text-white align-items-center">
            <motion.h2 initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="title-green pb-3">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.text.title1' }))}</motion.h2>
            <motion.p initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.text.p1' }))}
            </motion.p>
            <motion.p initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.text.p2' }))}
            </motion.p>
            <motion.h5 initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="pt-3 font-weight-bold line-height-normal letter-space-0"><span className="paragrafoGreen">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.text.title2' }))}</span></motion.h5>
          </div>
          <div className="col-lg-8 d-flex">
            <div className="row justify-content-center align-items-center">
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon1} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.pureza' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon2} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.oxidacao' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon3} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.termica' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon4} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.enxofre' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon5} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.aromaticos' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon6} text={parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.icones.volatil' }))} />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

const SectionSustentabilidade = ({ img2 }) => {
  const intl = useIntl()

  return (
    <div className="container p-lg-5 mt-5 mb-5 px-0">
      <div className="row m-0 p-0 align-items-center px-4 px-lg-0 mx-0">
        <div className="col-lg-4 ml-0 mb-lg-0 mb-3 px-0 px-lg-3">
          <h5 className="text-lwart-green font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.section_sutentabilidade.title1' }))}</h5>
          <h2 className="color-blue pt-3 pb-3">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.section_sutentabilidade.title2' }))} </h2>
          <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.section_sutentabilidade.p1' }))}
          </p>
          <b className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.g2.alto_desempenho.section_sutentabilidade.b1' }))}</b>
        </div>
        <div className="col-lg-8 mt-lg-0 mt-5 px-0 px-lg-3">
          <div className="row justify-content-center above position-absolute w-100 m-0" style={{ top: '-40px' }}>
            <div className="img text-center">
              <img src={handFlower} className="rounded-circle bg-lwart-green handflorG2" alt="Icone" />
            </div>
          </div>
          <GatsbyImage
            image={img2}
            className="imagem-residuos"
            alt="Imagem de uma moça da Lwart" />
        </div>
      </div>
    </div>
  )
}

const G2 = ({ data }) => {
  return <>
    <Pagina pagina={data.pagina} />
    <FloatSelo />
    <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
    <ProdutoSuperior img={data.img.childImageSharp.gatsbyImageData} arquivo={data.arquivo && data.arquivo.arquivo.file.url} />
    <AltoDesempenho />
    <SectionSustentabilidade img2={data.img2.childImageSharp.gatsbyImageData} />
  </>
}

export default G2

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/g2/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  arquivo: contentfulDocumentos(categoria: {eq: "g2"}) {
    arquivo {
      file {
        url
      }
    }
  }
  img: file(relativePath: {eq: "g2/g2a.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  img2: file(relativePath: {eq: "g2/shutterstock_1343726615a.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax: file(relativePath: {eq: "g2/shutterstock_1340501801a.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
